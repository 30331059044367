import {useEffect} from "react";
import {registerEvent, unregisterEvent} from "@/utils/custom-events";

const useCustomEventListener = (name, fn) => {
  useEffect(() => {
    const evtFunction = evt => fn(evt.detail);

    registerEvent(name, evtFunction);

    return () => {
      unregisterEvent(name, evtFunction);
    };
  }, [name, fn]);
};

export default useCustomEventListener;
